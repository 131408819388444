<template>

    <Head v-if="website.search.meta.meta_title!==undefined && website.search.meta.meta_title.length > 2">
            <title>{{website.search.meta.meta_title}}</title>
            <meta name="description" v-if="website.search.meta.meta_description" :content="website.search.meta.meta_description">
            <meta name="keywords" v-if="website.search.meta.meta_keywords" :content="website.search.meta.meta_keywords">

            <link rel="canonical" :href="route('search.page')+'/'+website.search.canonical.canonical"/>
            <link rel="prev" v-if="website.search.canonical.prev!==null" :href="route('search.page')+'/'+website.search.canonical.prev"/>
            <link rel="next" v-if="website.search.canonical.next!==null" :href="route('search.page')+'/'+website.search.canonical.next"/>

            <meta property="og:site_name" :content="website.name"/>
            <meta property="og:title" :content="website.search.meta.meta_title"/>
            <meta property="og:description" :content="website.search.meta.meta_description"/>
            <meta property="og:image:secure_url" content="https://cdn.bewinkel.com/website_logos/20161028084758_20160711141558stoelenvinden.png"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" :content="route('search.page')+'/'+website.search.canonical.canonical"/>
            <meta property="og:locale" content="nl"/>
    </Head>
    <Head v-else>
            <title>{{page.meta_title}}</title>
            <meta name="description" v-if="page.meta_description" :content="page.meta_description">
            <meta name="keywords" v-if="page.meta_keywords" :content="page.meta_keywords">


            <meta property="og:site_name"   :content="website.name"/>
            <meta property="og:title" :content="page.meta_title"/>
            <meta property="og:description"  :content="page.meta_description"/>
            <meta property="og:image:secure_url" content="https://cdn.bewinkel.com/website_logos/20161028084758_20160711141558stoelenvinden.png"/>
            <meta property="og:type"  content="website" />
            <meta property="og:url"  :content="route('search.page')+'/'+website.product"/>
            <meta property="og:locale" content="nl"/>
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"  @headerSearch="headerSearch"/>

    <section class="text-gray-600 body-font section-text">
        <div class="bg-white  dark:bg-black dark:text-white">

            <!-- Mobile filter dialog -->
                <TransitionRoot as="template" :show="mobileFiltersOpen">
                    <Dialog as="div" class="relative z-40 lg:hidden"
                            @close="mobileFiltersOpen = false">
                        <TransitionChild as="template"
                                         enter="transition-opacity ease-linear duration-300"
                                         enter-from="opacity-0" enter-to="opacity-100"
                                         leave="transition-opacity ease-linear duration-300"
                                         leave-from="opacity-100" leave-to="opacity-0">
                            <div class="fixed inset-0 bg-black bg-opacity-25"/>
                        </TransitionChild>

                        <div class="fixed inset-0 z-40 flex">
                            <TransitionChild as="template"
                                             enter="transition ease-in-out duration-300 transform"
                                             enter-from="translate-x-full" enter-to="translate-x-0"
                                             leave="transition ease-in-out duration-300 transform"
                                             leave-from="translate-x-0" leave-to="translate-x-full">
                                <DialogPanel
                                    class="relative ml-auto flex h-full w-full max-w-[310px] flex-col overflow-y-auto bg-white dark:bg-gray-700 dark:text-white py-4 pb-12 shadow-xl">
                                    <div class="flex items-center justify-between px-4">
                                        <h2 class="text-lg font-medium text-gray-900 dark:text-white">Filters</h2>
                                        <button type="button"
                                                class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                                @click.passive="mobileFiltersOpen = false">
                                            <span class="sr-only">Sluit menu</span>
                                            <XMarkIcon class="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>

                                    <!-- Filters -->
                                    <form class="mt-4 border-t border-gray-200">
                                        <h3 class="sr-only">Product categorieën</h3>
                                        <ul role="list"
                                            class="px-4 py-3   space-y-2 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900 ">
                                            <template v-for="(category,key,index) in website.categories" :key="category.url">
                                                <Disclosure as="sub_categories" class="" v-slot="{ open }" :defaultOpen="form.filters['categories']!==undefined
                                                                   && form.filters['categories'][0]!==undefined
                                                                   && form.filters['categories'][0]===category.url ">
                                                    <li :class="(index > 4 && categoryState==='hidden' ? 'hidden': ''+' !mt-0 pt-0')">
                                                        <jet-nav-link class="dark:text-white" :href="('/'+category.url)"
                                                                      :class="[

                                                                        form.filters['categories']!==undefined
                                                                       && form.filters['categories'][0]!==undefined
                                                                       && form.filters['categories'][0]===category.url ?
                                                'font-bold':'','!no-underline block py-2']"
                                                        >
                                                            {{category.name }}
                                                            <DisclosureButton class="float-right" v-if=" category.sub_categories.length!==0"  :title="'Subcategorieën van '+category.name ">
                                                            <span class="ml-6 flex items-center float-right  py-1">
                                                              <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                              <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                            </span>
                                                            </DisclosureButton>
                                                        </jet-nav-link>

                                                        <ul v-if=" category.sub_categories.length!==0">
                                                            <DisclosurePanel class="text-gray-500">
                                                                <template v-for="(sub,key2,index2) in category.sub_categories" :key="sub.name">
                                                                    <li v-if="sub!==undefined">
                                                                        <jet-nav-link class="dark:text-white ml-5" :href="('/'+sub.url)"
                                                                                      :class="[
                                                                        form.filters['categories']!==undefined
                                                                       && form.filters['categories'][1]!==undefined
                                                                       && form.filters['categories'][0]+'/'+form.filters['categories'][1]===sub.url ?
                                                                          'font-bold':'','!no-underline block py-1']"
                                                                        >{{sub.name}}</jet-nav-link>
                                                                    </li>
                                                                </template>
                                                            </DisclosurePanel>
                                                        </ul>
                                                    </li>
                                                </Disclosure>
                                            </template>
                                            <li class=' py-1 text-blue-600 cursor-pointer text-sm dark:text-white' v-if="categoryState==='hidden'" @click.passive="showCategory">
                                                <CustomSvg :name="'chevron-down'" color="red" class=" text-blue-100 mr-2 dark:text-white"></CustomSvg>
                                                Meer
                                            </li>
                                            <li class='px-2 py-1 text-blue-600 cursor-pointer text-sm dark:text-white' v-if="categoryState==='show'  || show_category===undefined" @click.passive="hideCategory">
                                                <CustomSvg :name="'chevron-up'" color="red" class=" text-blue-100 mr-2 dark:text-white"></CustomSvg>
                                                Minder
                                            </li>
                                        </ul>
                                        <Disclosure as="div" v-for="(filter,key) in website.search.filters" class=""  v-slot="{ open }" :defaultOpen="true"><!--(key==='color')-->
                                            <div class="border-b border-gray-200 py-6  " v-if="key !== 'categories' && filter.length!==0">
                                                <h3 class="-my-3 flow-root m-4" :ref="'group'+key">
                                                    <DisclosureButton class="flex w-full items-center justify-between py-3 text-sm text-gray-400  hover:text-gray-500" >
                                                        <span class="font-medium text-gray-900 dark:text-white">{{website.filter_names[key] ?? ''}}</span>
                                                        <span class="ml-6 flex items-center">
                                                            <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                            <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    </DisclosureButton>
                                                </h3>
                                                <DisclosurePanel class="pt-6" >
                                                    <div :class="' px-4 '+(key==='color' ? 'grid grid-cols-6 gap-4 gap-y-1':'space-y-1') ">
                                                        <div v-for="(filter_option, optionIdx) in filter"
                                                             :key="filter_option.value">
                                                            <!-- copy marktplaats   -->
                                                            <template v-if="key==='color'">
                                                                <a :href="route('search.page')+'/'+filter_option.type_url" @click.self.prevent class="!no-underline  w-full block w-full" >
                                                                <input
                                                                    :class="(filter_option.url==='diverse-kleuren' ? 'invert-custom rainbow ' : 'invert-custom' ) +' w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 focus:ring-2'"
                                                                    type="checkbox"
                                                                    v-model="form.filters[key]"
                                                                    :value="filter_option.url"
                                                                    :name="'question_answer_' + filter_option.id"
                                                                    :id="'question_answer_' + filter_option.id">
                                                                    <label :title=" filter_option.name"
                                                                           class="text-sm font-medium text-gray-900"
                                                                           :style="'--my-color-var:'+filter_option.code+'; --my-color-check:' +(ifIsWhite(filter_option.code) ? 'black':'white') "
                                                                           :for="'question_answer_' + filter_option.id">
                                                                        <span class="sr-only">{{filter_option.name }}</span>
                                                                    </label>
                                                                </a>
                                                            </template>
                                                            <template v-if="key!=='color'">
                                                                <div class="flex items-center" :class="(isHiddenCheckbox(optionIdx,key,filter_option.url) ? 'hidden': '')">

                                                                <input
                                                                    :class="' w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2'"
                                                                    type="checkbox"
                                                                    v-model="form.filters[key]"
                                                                    :value="filter_option.url"
                                                                    :name="'question_answer_' + filter_option.id"
                                                                    :id="'question_answer_' + filter_option.id">
                                                                    <a :href="route('search.page')+'/'+filter_option.type_url" @click.self.prevent class="!no-underline  w-full block w-full" >
                                                                    <label
                                                                        :class="(isHiddenCheckbox(optionIdx,key,filter_option.url) ? 'hidden': '') + ' ml-2  text-sm font-medium text-gray-900 dark:text-white'"
                                                                        :for="'question_answer_' + filter_option.id">
                                                                        {{ filter_option.name }}
                                                                        <span class="text-xs">({{ filter_option.doc_count_human }})</span>
                                                                    </label>
                                                                </a>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <template v-if="key!=='color' && filter.length > 2">
                                                            <div class='w-auto h-4 text-blue-600 cursor-pointer text-sm dark:text-white' v-if="showGroupStatus(key)===false" @click.passive="showGroup(key)">
                                                                <CustomSvg :name="'chevron-down'" color="red" class=" text-blue-100 mr-2 dark:text-white"></CustomSvg>
                                                                Meer
                                                            </div>
                                                            <div class='w-auto h-4 text-blue-600 cursor-pointer text-sm dark:text-white' v-if="showGroupStatus(key)==true" @click.passive="hideGroup(key)">
                                                                <CustomSvg :name="'chevron-up'" color="red" class=" text-blue-100 mr-2 dark:text-white"></CustomSvg>
                                                                Minder
                                                            </div>
                                                        </template>
                                                    </div>
                                                </DisclosurePanel>
                                            </div>
                                        </Disclosure>
                                        <Disclosure as="div2" :defaultOpen="true" v-slot="{ open }">
                                            <div class="border-b border-gray-200 py-6 p-4">
                                                <h3 class="-my-3 flow-root">
                                                    <DisclosureButton
                                                        class="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                              <span
                                                  class="font-medium text-gray-900 dark:text-white">Prijs</span>
                                                        <span class="ml-6 flex items-center">
                                                  <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                  <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                                    </DisclosureButton>
                                                </h3>
                                                <DisclosurePanel class="pt-6" v-if="min!==undefined">
                                                    <div class="clear-both p-2">
                                                        <MultiRangeSlider
                                                            baseClassName="multi-range-slider-bar-only"
                                                            :minValue="form.min_price"
                                                            :maxValue="form.max_price"
                                                            :max="max+1"
                                                            :min="min"
                                                            :step="5"
                                                            :rangeMargin="0"
                                                            :ruler="false"
                                                            @input="updateBarValues"
                                                        />
                                                    </div>
                                                    <div class="text-gray-600 text-s p-2 dark:text-gray-300">Prijs vanaf <b>{{money_format(form.min_price)}}</b> tot <b>{{money_format(form.max_price)}}</b> </div>
                                                </DisclosurePanel>
                                            </div>
                                        </Disclosure>
                                    </form>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </Dialog>
                </TransitionRoot>

                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div
                        class="sm:flex sm:items-center sm:justify-between border-b border-gray-200 pt-3 pb-3 dark:border-gray-700" id="title">
                        <div class="align-top mb-2">
                            <h1 class="text-base lg:text-4xl font-bold text-gray-900 tracking-tight sm:tracking-normal dark:text-white">
                              {{h1Title()}}
                            </h1>
                            <div class="text-xs lg:text-sm tracking-tight " v-if="website.search.products_total > 0">
                                {{website.search.products_total}}
                                <span v-if="website.search.products_total==1">
                                    resultaat
                                </span>
                                <span v-if="website.search.products_total > 1">
                                    resultaten
                                </span>
                            </div>
                        </div>

                        <div class="flex justify-between">
                            <div class="lg:hidden">
                                <button type="button"
                                        @click.passive="mobileFiltersOpen = true"
                                        class=" text-sm font-medium text-gray-700 dark:text-white hover:text-green-900 border-green-700 border rounded p-1.5  !no-underline">
                                <span class="whitespace-nowrap text-xs mr-1  ">
                                    <CustomSvg :name="'funnel'" color="red" class="text-gray-600 mr-1 dark:text-white  "
                                               :customClass="'fill-gray-500 w-3  ml-2 '"></CustomSvg>
                                    Filters
                                </span>
                                </button>

                                <div class="float-right text-sm ml-1">
                                      <DeleteAllFilters  :website="website" :sorting="form.sorting" :min="min" :max="max"
                                                       @website_filter_reset="onRemoveFilters"></DeleteAllFilters>
                                </div>

                            </div>


                            <span>
                                <ProductPaginate :website="website" @website_search="onChangeWebsiteSearch"></ProductPaginate>
                            </span>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" class="pt-6 pb-24">
                        <h2 id="products-heading" class="sr-only">Producten</h2>

                        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                            <!-- Filters DESKTOP -->
                            <form class="hidden lg:block">
                                <h3 class="sr-only">Product categorieën</h3>
                                <ul role="list"
                                    class="space-y-4 border-b border-gray-200 dark:border-gray-600 pb-6 text-sm font-medium text-gray-900 dark:text-white">
                                    <template v-for="(category,key,index) in website.categories" :key="category.url">
                                        <Disclosure as="sub_categories" class="" v-slot="{ open }" :defaultOpen="form.filters['categories']!==undefined
                                                                   && form.filters['categories'][0]!==undefined
                                                                   && form.filters['categories'][0]===category.url ">
                                            <li :class="(index > 4 && categoryState==='hidden' ? 'hidden': ''+' !mt-0 pt-0')">
                                                <jet-nav-link class="dark:text-white" :href="('/'+category.url)"
                                                              :class="[

                                                                        form.filters['categories']!==undefined
                                                                       && form.filters['categories'][0]!==undefined
                                                                       && form.filters['categories'][0]===category.url ?
                                                'font-bold':'','!no-underline block py-2']"
                                                >
                                                    {{category.name }}
                                                    <DisclosureButton class="float-right" v-if=" category.sub_categories.length!==0" :title="'Subcategorieën van '+category.name ">
                                                            <span class="ml-6 flex items-center float-right  py-1">
                                                              <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                              <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                            </span>
                                                    </DisclosureButton>
                                                </jet-nav-link>

                                                <ul v-if=" category.sub_categories.length!==0">
                                                    <DisclosurePanel class="text-gray-500">
                                                    <template v-for="(sub,key2,index2) in category.sub_categories" :key="sub.name">
                                                        <li v-if="sub!==undefined">
                                                            <jet-nav-link class="dark:text-white ml-5" :href="('/'+sub.url)"
                                                                          :class="[
                                                                        form.filters['categories']!==undefined
                                                                       && form.filters['categories'][1]!==undefined
                                                                       && form.filters['categories'][0]+'/'+form.filters['categories'][1]===sub.url ?
                                                                          'font-bold':'','!no-underline block py-1']"
                                                            >{{sub.name}}</jet-nav-link>
                                                        </li>
                                                    </template>
                                                    </DisclosurePanel>
                                                </ul>
                                            </li>
                                            </Disclosure>
                                    </template>

                                    <li class=' py-1 text-blue-600 cursor-pointer text-sm  dark:text-gray-100' v-if="categoryState==='hidden'" @click.passive="showCategory">
                                        <CustomSvg :name="'chevron-down'" color="blue" class="w-4 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                        Meer
                                    </li>

                                    <li class='px-2 py-1 text-blue-600 cursor-pointer text-sm  dark:text-gray-100' v-if="categoryState==='show'  || show_category===undefined" @click.passive="hideCategory">
                                        <CustomSvg :name="'chevron-up'" color="blue" class=" w-4 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                        Minder
                                    </li>

                                </ul>

                                <Disclosure as="div" v-for="(filter,key) in website.search.filters"
                                            class=""  v-slot="{ open }" :defaultOpen="true"><!--(key==='color')-->
                                    <div class="border-b border-gray-200 dark:border-gray-600  py-6"
                                         v-if="key !== 'categories' && filter.length!==0">
                                        <h3 class="-my-3 flow-root">
                                            <DisclosureButton
                                                class="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                                <span
                                                    class="font-medium text-gray-900 dark:text-gray-200">{{
                                                        website.filter_names[key] ?? ''
                                                    }}</span>
                                                <span class="ml-6 flex items-center">
                                                  <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                  <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                            </DisclosureButton>
                                        </h3>

                                        <DisclosurePanel class="pt-6" >

                                            <div
                                                :class="(key==='color' ? 'grid grid-cols-6 gap-4 gap-y-1':'space-y-4') ">
                                                <div v-for="(filter_option, optionIdx) in filter"
                                                     :key="filter_option.value">


                                                    <!-- copy marktplaats   -->
                                                    <template v-if="key==='color'">
                                                        <a :href="route('search.page')+'/'+filter_option.type_url" @click.self.prevent class="!no-underline">
                                                        <input
                                                            :class="(filter_option.url==='diverse-kleuren' ? 'invert-custom rainbow ' : 'invert-custom' ) +' w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 focus:ring-2'"
                                                            type="checkbox"
                                                            v-model="form.filters[key]"
                                                            :value="filter_option.url"
                                                            :name="'question_answer_' + filter_option.id"
                                                            :id="'question_answer_' + filter_option.id">

                                                        <label :title=" filter_option.name"
                                                               class="text-sm font-medium text-gray-900"
                                                               :style="'--my-color-var:'+filter_option.code+'; --my-color-check:' +(ifIsWhite(filter_option.code) ? 'black':'white') "
                                                               :for="'question_answer_' + filter_option.id">
                                                           <span class="sr-only">   {{
                                                                    filter_option.name
                                                                }}</span>
                                                        </label>
                                                        </a>
                                                    </template>
                                                    <template v-if="key!=='color'">
                                                        <div :class="(isHiddenCheckbox(optionIdx,key,filter_option.url) ? 'hidden': '')" class="flex items-center">

                                                           <input
                                                                :class="' w-4 h-4 text-blue-600  bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2'"
                                                                type="checkbox"
                                                                v-model="form.filters[key]"
                                                                :value="filter_option.url"
                                                                :name="'question_answer_' + filter_option.id"
                                                                :id="'question_answer_' + filter_option.id">
                                                            <a :href="route('search.page')+'/'+filter_option.type_url" @click.self.prevent class="!no-underline w-full block w-full" >
                                                                <label
                                                                    :class="(isHiddenCheckbox(optionIdx,key,filter_option.url) ? 'hidden': '') + ' ml-2  text-sm font-medium text-gray-900 dark:text-gray-100'"
                                                                    :for="'question_answer_' + filter_option.id">
                                                                    {{ filter_option.name }}
                                                                    <span class="text-xs">({{ filter_option.doc_count_human }})</span>
                                                                </label>
                                                            </a>
                                                        </div>
                                                    </template>

                                                </div>

                                                <template v-if="key!=='color' && filter.length > 2">
                                                    <div class='w-auto h-4 text-blue-600 cursor-pointer text-sm dark:text-gray-100' v-if="showGroupStatus(key)===false" @click.passive="showGroup(key)">
                                                        <CustomSvg :name="'chevron-down'" color="blue" class="w-4 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                                        Meer
                                                    </div>
                                                    <div class='w-auto h-4 text-blue-600 cursor-pointer text-sm dark:text-gray-100' v-if="showGroupStatus(key)==true" @click.passive="hideGroup(key)">
                                                        <CustomSvg :name="'chevron-up'" color="blue" class="w-4 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                                        Minder
                                                    </div>
                                                </template>
                                            </div>
                                        </DisclosurePanel>
                                    </div>
                                </Disclosure>

                                <Disclosure as="div"  v-slot="{ open }" :defaultOpen="true" v-if="website.search.products_total>0 && min!==undefined">
                                    <div class="border-b border-gray-200 dark:border-gray-600  py-6">
                                        <h3 class="-my-3 flow-root">
                                            <DisclosureButton
                                                class="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                                              <span
                                                  class="font-medium text-gray-900 dark:text-gray-100">Prijs</span>
                                                <span class="ml-6 flex items-center">
                                                  <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true"/>
                                                  <MinusIcon v-else class="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel class="pt-6">
                                            <div class="clear-both p-2">
                                                <MultiRangeSlider
                                                    baseClassName="multi-range-slider-bar-only"
                                                    :minValue="form.min_price"
                                                    :maxValue="form.max_price"
                                                    :max="max+1"
                                                    :min="min"
                                                    :step="5"
                                                    :rangeMargin="0"
                                                    :ruler="false"
                                                    @input="updateBarValues"
                                                />
                                            </div>

                                            <div class="text-gray-600 text-s p-2 dark:text-gray-300">Prijs vanaf <b>{{money_format(form.min_price)}}</b> tot <b>{{money_format(form.max_price)}}</b> </div>
                                        </DisclosurePanel>
                                    </div>
                                </Disclosure>
                            </form>

                            <!-- Product grid -->
                            <div class="lg:col-span-3  lg:h-full">
                                <ProductOrder :website="website" :sorting="form.sorting" :min="min" :max="max" :mobileFiltersOpen="mobileFiltersOpen"
                                              @mobileFiltersOpen="onChangeMobileFiltersOpen"
                                              @website_search="onChangeWebsiteSearch"
                                              @website_filter_reset="onRemoveFilters"
                                              @website_filter_reset_price="onRemoveFilterPrice"
                                ></ProductOrder>

                                <ProductIndex :products="website.search.products" :website="website"></ProductIndex>
                                <!-- PRODUCTS -->
                            </div>

                            <!-- /End products -->
                            <div class="lg:col-end-5 lg:col-span-3">
                                <div class="float-right clear-both flex items-center">
                                    <ProductPaginate :website="website"  @website_search="onChangeWebsiteSearch"  @website_filter_reset="onRemoveFilters"></ProductPaginate>
                                </div>
                                <div class="clear-both pt-2 text-sm" v-if="website.search.meta.title">
                                    <h3 class="mb-2" v-html="website.search.meta.title"></h3>
                                    <p v-html="website.search.meta.description"></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
    </section>
    <Footer :menu_items="menu_items" :page="page"  :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>
import {ref} from 'vue'
import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import ProductIndex from '@/Pages/Admin/Page/Templates/Partials/ProductIndex.vue';
import ProductOrder from '@/Pages/Admin/Page/Templates/Partials/ProductOrder.vue';
import ProductPaginate from '@/Pages/Admin/Page/Templates/Partials/ProductPaginate.vue';
import MultiRangeSlider from 'multi-range-slider-vue';
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';

import axios from 'axios';
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import JetNavLink from '@/Jetstream/NavLink.vue'
import { Head } from '@inertiajs/vue3'

import {
    FunnelIcon,
    MinusIcon,
    PlusIcon,
    Squares2X2Icon,
} from '@heroicons/vue/20/solid'
import DeleteAllFilters from "@/Pages/Admin/Page/Templates/Partials/DeleteAllFilters.vue";

export default {

    components: {
        DeleteAllFilters,
        Header,
        Footer,
        Dialog,
        DialogPanel,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon,
        XMarkIcon,
        JetNavLink,
        ProductIndex,
        ProductOrder,
        ProductPaginate,
        Head,
        MultiRangeSlider,
        CustomSvg
    },
    props: {
        title: String,
        menu_items: Object,
        page: Object,
        editable: Boolean,
        products: Object,
        website: Object,
     //   max:Number,
     //   min:Number,
    },

    data() {
        return {
            min:this.website.search.min_price/100,
            max:this.website.search.max_price/100,
            show_category:'',
            form:{
                filters: this.website.search.selected_filters,
                sorting: this.website.search.sorting,
                page: this.website.search.page,
                q: this.website.search.q,
                min_price: this.website.search.min_price/100,
                max_price: this.website.search.max_price/100,
            },
            mobileFiltersOpen: ref(false),
            myTimeout: null,
            show_hide_filters: [],
           // user_min_price: this.website.search.min_price/100+9,
           // user_max_price: this.website.search.max_price/100,
        }
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        },
        myStopFunction() {
            if (this.myTimeout) {
                clearTimeout(this.myTimeout);
            }
        },
        sendForm() {
            this.myStopFunction();
            let self = this;
            this.myTimeout = setTimeout(self.sendFormDelayed, 100);
        },
        sendFormDelayed() {
            axios.post(route('search.products'), {
                filters: this.form.filters,
                search: this.search,
                sorting: this.form.sorting,
                page: this.form.page,
                q: this.form.q,
                min_price: this.form.min_price,
                max_price: this.form.max_price,
            }).then((response) => {
                this.website.search = response.data;
                this.$inertia.page.url ='/'+ this.website.search.url;
                this.$inertia.replaceState(this.$inertia.page)
                //history.replaceState(history.state, '', location.origin +'/'+ this.website.search.url); // Dont work!!
            })
        },

        previous() {
            if (this.website.search.page_previous >= 0) {
                this.form.page = this.website.search.page_previous;
                this.sendForm();
            }
        },
        next() {
            this.form.page = this.form.page + 1;
            this.sendForm()
        },

        ifIsWhite(code) {
            if (code === '#F0E8C4') {
                return true;
            }
            if (code === '#E5DCDF') {
                return true;
            }
            if (code === '#F1E9E7') {
                return true;
            }
            if (code === '#FFFFFF') {
                return true;
            }
        },
        //from parent
        onChangeWebsiteSearch(search){
            this.form.q = search.q;
            this.form.page = search.page;
            this.form.sorting = search.sorting;
            this.sendForm();
        },
        onChangeMobileFiltersOpen(temp,group){
            this.mobileFiltersOpen = temp;
            this.$nextTick(() => {
                console.log(this.$refs['group'+group]);
                // block: 'end' will scroll to top of element instead of bottom
                this.$refs['group'+group][0].scrollIntoView({ behavior: "smooth" })
            });

        },
        onRemoveFilters(search){
            console.log('onRemoveFilters');
            this.form.page = 0;
            this.form.q ='';
            this.form.min_price = this.min;
            this.form.max_price= this.max;
            this.form.filters = search.selected_filters;
            this.sendForm();
        },
        onRemoveFilterPrice(){
            this.form.min_price = this.min;
            this.form.max_price= this.max;
            this.form.page = 0;
            this.sendForm();
        },
        //from parent
        headerSearch(search){
            console.log('headerSearch');
            this.form.q = search;
            this.form.page = 0;
            this.sendForm();
        },

        showCategory() {
            console.log('showCategory');
            this.show_category = 'show';
            this.setCookie('show_category', 'show', 1);
        },
        hideCategory() {
            console.log('hideCategory');
            this.show_category = 'hidden';
            this.setCookie('show_category', 'hidden', 1);
        },
        isHiddenCheckbox(index,key,value){

            if(this.form.filters[key].includes(value)){
                return false;
            }
            if(this.show_hide_filters[key]===undefined){
                this.show_hide_filters[key] = false;
            }
            if(index > 2 && this.show_hide_filters[key]===false){
                return true;
            }
            return false;
        },
        showGroupStatus(key){
           if(!this.show_hide_filters[key] || this.show_hide_filters[key]===false){
               return false;
           }
           return true;
        },
        showGroup(key){
            this.show_hide_filters[key] = true;
        },
        hideGroup(key){
            this.show_hide_filters[key] = false;
        },
        h1Title(){
            return this.website.search.title;
            if(this.website.search.meta.title!==undefined && this.website.search.meta.title!==''){
                return this.website.search.meta.title;
            }else{
               return this.website.search.title
            }
        },
        updateBarValues(e) {
           // this.user_min_price = e.minValue;
            //this.user_max_price = e.maxValue;

            this.form.min_price = e.minValue;
            this.form.max_price = e.maxValue
            this.sendForm();
        },
    },

    watch: {
        'form.filters': {
            handler(val, oldVal) {

                this.form.page = 0;
                this.sendForm();
            },
            deep: true
        }
    },
    computed: {
        categoryState(){
            if( this.show_category !=='show' &&  this.show_category!=='hidden') {
                let cookie_cat = this.getCookie('show_category');
                if(cookie_cat !=='show' && cookie_cat!=='hidden'){
                    this.hideCategory();
                    cookie_cat = this.getCookie('show_category');
                }
                this.show_category =cookie_cat;
                return cookie_cat;
            }
            return this.show_category;
        },
    }
}
</script>

